import {
  Row,
  Col,
  Button,
  Spinner
} from "reactstrap";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';




const ListTools = () => {


  const [toolResponse, settoolResponse] = useState("");
  const [tableRun, setTableRun] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [waitResponse, setWaitResponse] = useState(true);

  const cookie = new Cookies();
  const navigate = useNavigate();
  const tableRef = useRef();

  // index function
  const list_tool = async () => {
    try {
      const token = cookie.get("authToken");

      const [responseImagination, responseReWrite] = await Promise.all([
        axios({
          method: "get",
          url: `${process.env.REACT_APP_SELECT_IMAGINATION}`,
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        }),
        axios({
          method: "get",
          url: `https://varnz-apps-backend.webgarh.net/api/re-write`,

          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        })
      ]);
      const combineData = [...responseImagination.data, ...responseReWrite.data]
      // const response = await axios({
      //   method: "get",
      //   url: `${process.env.REACT_APP_SELECT_IMAGINATION}`,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'x-auth-token': token,
      //   },
      // });
      //  console.log(combineData, 'combineData');

      settoolResponse(combineData).sort();
      setTableRun(true);
      setWaitResponse(false);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  // delete function
  const deleteTool = async (toolId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary blog!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = cookie.get("authToken");
          const response = await axios.delete(`${process.env.REACT_APP_SELECT_IMAGINATION}/${toolId}`, {
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token,
            },
          });
          if (response.data.status === 1) {
            toast.success("Deleted Successful!")
            settoolResponse(toolResponse.filter(item => item._id !== toolId));
          } else {
            toast.error(`Error: ${response.data.message}`)
          }
        } catch (error) {
          console.error("Error deleting data:", error);
        }
      }
    });
  }

  const handleSelectChange = async (event, index, toolId) => {
    setSelectedValue((oldData) => {
      return {
        ...oldData,
        [index]: event.target.value
      }
    });
    try {
      const token = cookie.get("authToken");
      const formData = {
        status: event.target.value,
        type: "status"
      }
      const response = await axios.put(`${process.env.REACT_APP_SELECT_IMAGINATION}/${toolId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        }
      );

      if (response.data.status === 1) {
        toast.success(response.data.message);
      } else {
        setSelectedValue((oldData) => {
          return {
            ...oldData,
            [index]: ""
          }
        });
        toast.error(response.data.message)
      }

    } catch (err) {

    }
  };

  const editTool = (id) => {
    return navigate(`/update-select-tool/${id}`);
  }

  useEffect(() => {
    list_tool();
  }, []);

  useEffect(() => {
    if (tableRun) {
      $(tableRef.current).DataTable();
    }
  }, [tableRun]);


  return (
    <div>
      <ToastContainer />
      <div className="admin-topbar">
        <h5 className="Main-heading">AI Tools</h5>
        <div class="submit-buttons">
          <Button
            color="primary"
            onClick={() => {
              return navigate("/tools")
            }}
          >
            + Add Tool
          </Button>
        </div>
      </div>
      {
        true &&
        <Row>
          <Col sm="12" lg="12" xl="12" className="blog-listing">
            <div className="card card-body blog-details">
              <div className="blog-list">
                <table ref={tableRef} id="myTable" className="table">
                  <thead>
                    <tr>
                      <th>Display Name</th>
                      <th>Key</th>
                      <th>Limit</th>
                      <th>Position</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      toolResponse && toolResponse.map((data, index) => {
                        return <tr>
                          <td>
                            <div className="blog-list-table">
                              <div class="blog-list-title">
                                <h4>{data?.label}</h4>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="blog-list-table">
                              <div class="blog-list-title">
                                <h4>{data?.key}</h4>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="blog-list-table">
                              <div class="blog-list-title">
                                <h4>{data?.limit}</h4>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="blog-list-table">
                              <div class="blog-list-title">
                                <h4>{data?.position}</h4>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="badge" style={{ textTransform: "capitalize" }}>
                              <select
                                className={`${selectedValue?.[index] ? selectedValue?.[index] === "published" ? 'badge-success' : 'badge-danger' : data?.isActive ? 'badge-success' : 'badge-danger'}`}
                                value={selectedValue?.[index] || (data?.isActive ? "published" : "draft")}
                                onChange={(e) => handleSelectChange(e, index, data._id)}>
                                <option value="draft">Draft</option>
                                <option value="published">Published</option>
                              </select>
                            </div></td>
                          <td>

                            <ul className="action-btns">
                              <Tooltip title="Edit">
                                <li className="edit-icon" onClick={() => { editTool(data._id) }}><span><i class="fas fa-pencil-alt"></i></span></li>
                              </Tooltip>
                              {
                                data.key !== "not_in_the_list" &&
                                <Tooltip title="Delete" >
                                  <li className="delete-icon" onClick={() => { deleteTool(data._id) }}><span><i class="fas fa-trash-alt"></i></span></li>
                                </Tooltip>
                              }

                            </ul>
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>

      }


    </div>
  );
};

export default ListTools;
